import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Typography,
    styled,
    useMediaQuery,
} from '@mui/material';
import { colors } from '../assets/utils/colors';
import b1 from '../assets/media/books/book1.webp';
import b2 from '../assets/media/books/book2.webp';
import b3 from '../assets/media/books/book3.webp';
import b4 from '../assets/media/books/book4.webp';
import b5 from '../assets/media/books/book5.webp';
import CustomDialog from '../components/dialogs/CustomDialog';

const SectionHead = styled(Box)({
    'marginTop': 50,
    'marginBottom': 20,
    'padding': 10,
    'position': 'relative',
    '& .head': {
        'fontSize': 18,
        'fontWeight': 'bold',
        'opacity': 0.7,
        // 'textAlign': 'center',
        // 'display': 'flex',
        'justifyContent': 'center',
        ':before': {
            content: "''",
            height: 8,
            width: 100,
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
            position: 'absolute',
            bottom: 0,
            borderRadius: 50,
        },
    },
});

const Books = () => {
    const ref = React.useRef(null);

    const matches = useMediaQuery('(min-width:600px)');
    const [openDialog, setOpenDialogy] = React.useState(false);
    const [bookPreview, setBookPreview] = React.useState({});

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // -> Handle Illustrator preview
    const handleBookPreview = (book) => {
        setBookPreview(book);
        setOpenDialogy(true);
    };

    // => books
    const books = [
        {
            id: 1,
            file: b1,
            title: 'Hammy and Friends',
            released: '29th July 2019',
            author: 'Godwill Belium',
            description:
                "Hammy's adventures start right here... Hammy has a new home, but isn't impressed. He escapes and ends up going on an adventure, where he meets lots of friends.",
        },
        {
            id: 1,
            file: b2,
            title: 'The Haunted Kingdom',
            released: '29th July 2019',
            author: 'Godwill Belium',
            description:
                'Hammy and Friends end up out at sea and their journey comes to a stop at a Haunted Kingdom. With Barry going missing, will they find him?',
        },
        {
            id: 1,
            file: b3,
            title: 'The Magical Fountain',
            released: '29th July 2019',
            author: 'Godwill Belium',
            description:
                'They visit The Enchanted Forest, which is under threat by the Evil Lioness, can Hammy and his friends save the forest?',
        },
        {
            id: 1,
            file: b4,
            title: 'The Mad Science Lab',
            released: '29th July 2019',
            author: 'Godwill Belium',
            description:
                'Frank visits an old science lab, as they all try to cheer him up, what could possibly go wrong?',
        },
        {
            id: 1,
            file: b5,
            title: 'The Animal Society',
            released: '29th July 2019',
            author: 'Godwill Belium',
            description:
                'In the final adventure, it ends where it all began; The Animal Society, will everyone reunite?',
        },
    ];

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={bookPreview.title}
                customWidth={'md'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <Grid container spacing={2}>
                    <Grid item sm={7} xs={12}>
                        <ImageListItem>
                            <img
                                src={`${bookPreview.file}?w=248&fit=crop&auto=format`}
                                srcSet={`${bookPreview.file}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={bookPreview.title}
                                loading="lazy"
                                style={{ height: `100%`, objectFit: 'contain' }}
                            />
                        </ImageListItem>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <Box>
                            <SectionHead
                                sx={{ marginTop: '0px !important', px: 0 }}
                            >
                                <Typography className="head">
                                    Book description
                                </Typography>
                            </SectionHead>
                            <Typography sx={{ opacity: 0.8 }}>
                                {bookPreview.description}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        opacity: 0.8,
                                        fontWeight: 'bold',
                                        fontSize: 18,
                                    }}
                                >
                                    Released :{' '}
                                </Typography>
                                <Typography sx={{ opacity: 0.8 }}>
                                    {bookPreview.released}{' '}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    mt: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        opacity: 0.8,
                                        fontWeight: 'bold',
                                        fontSize: 18,
                                    }}
                                >
                                    Author :{' '}
                                </Typography>
                                <Typography sx={{ opacity: 0.8 }}>
                                    {bookPreview.author}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CustomDialog>

            {/* ############### Content ################### */}
            <Box sx={{ pb: 7 }} ref={ref}>
                <Box>
                    <Grid container justifyContent={'center'} padding={3}>
                        <Grid item sm={9} xs={12}>
                            <SectionHead>
                                <Typography className="head">
                                    Children books illustrations
                                </Typography>
                            </SectionHead>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <ImageList
                                cols={matches ? 3 : 1}
                                gap={25}
                                variant="quilted"
                            >
                                {books?.map((book, index) => {
                                    return (
                                        <ImageListItem
                                            key={index}
                                            sx={{
                                                'cursor': 'pointer',
                                                ':hover': {
                                                    opacity: 0.5,
                                                    transition: `.2s all ease-in-out`,
                                                },
                                            }}
                                            onClick={() =>
                                                handleBookPreview(book)
                                            }
                                        >
                                            <img
                                                src={`${book.file}?w=248&fit=crop&auto=format`}
                                                srcSet={`${book.file}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={book.title}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={book.title}
                                                subtitle={
                                                    <span>
                                                        by: {book.author}
                                                    </span>
                                                }
                                                position="bottom"
                                                sx={{ opacity: 0.8 }}
                                            />
                                        </ImageListItem>
                                    );
                                })}
                            </ImageList>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default Books;
