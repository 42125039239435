import React from 'react';
import { Instagram, Message, Phone } from '@mui/icons-material';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { colors } from '../assets/utils/colors';

const Contacts = () => {
    const contacts = [
        {
            id: 1,
            tooltip: 'Phone',
            value: '+255743016809',
            icon: <Phone />,
            url: 'tel:+255743016809',
        },
        {
            id: 2,
            tooltip: 'mortnypofh@gmail.com',
            value: 'mortnypofh@gmail.com',
            icon: <Message />,
            url: 'mailTo:mortnypofh@gmail.com',
            badge: 1,
        },
        {
            id: 3,
            tooltip: 'godwillbelium@mortbelium.com',
            value: 'godwillbelium@mortbelium.com',
            icon: <Message />,
            url: 'mailTo:godwillbelium@mortbelium.com',
            badge: 2,
        },
        {
            id: 4,
            tooltip: 'Instagram',
            value: 'https://www.instagram.com/mort_belium/',
            icon: <Instagram />,
            url: 'https://www.instagram.com/mort_belium/',
        },
    ];
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                {contacts?.map((contact) => (
                    <Box
                        sx={{
                            width: '100%',
                            p: 1,
                            textAlign: 'center',
                            borderRadius: 2,
                        }}
                    >
                        <Tooltip title={contact.tooltip} arrow>
                            <a
                                href={contact.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <IconButton
                                    color="info"
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    }}
                                >
                                    {contact.icon}
                                    {contact.badge && (
                                        <Badge
                                            badgeContent={contact.badge}
                                            color="secondary"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                            }}
                                        />
                                    )}
                                </IconButton>
                            </a>
                        </Tooltip>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default Contacts;
