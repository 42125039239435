import React from 'react';
import {
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Paper,
    Typography,
} from '@mui/material';
import { colors } from '../assets/utils/colors';
import logo from '../assets/media/images/logo.png';
import { Link } from 'react-router-dom';

const TopNavbar = () => {
    return (
        <>
            <Paper
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    boxShadow: 'none',
                    zIndex: 999,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1}) !important`,
                }}
            >
                <BottomNavigation
                    showLabels
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1}) !important`,
                    }}
                >
                    <BottomNavigationAction
                        label={
                            <Link to={'/'}>
                                <Typography
                                    noWrap
                                    component="div"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar
                                        src={logo}
                                        variant="square"
                                        sx={{
                                            width: 100,
                                            img: {
                                                objectFit: 'contain',
                                                opacity: 0.8,
                                            },
                                        }}
                                    />
                                </Typography>
                            </Link>
                        }
                    />
                </BottomNavigation>
            </Paper>
        </>
    );
};

export default TopNavbar;
