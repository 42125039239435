import { configureStore } from '@reduxjs/toolkit';

// -> SLICE reducer
import illustrationsReducer from '../features/illustrationsSlice';
import animationsReducer from '../features/animationsSlice';
import testimonialsReducer from '../features/testimonialsSlice';

// -> RTK reducer
import { illustrationAPI } from '../../api/illustrationAPI';
import { animationAPI } from '../../api/animationAPI';
import { testimonialAPI } from '../../api/testimonialAPI';

// !########### STORE ###########
export const store = configureStore({
    reducer: {
        [illustrationAPI.reducerPath]: illustrationAPI.reducer,
        [animationAPI.reducerPath]: animationAPI.reducer,
        [testimonialAPI.reducerPath]: testimonialAPI.reducer,
        illustrationsReducer,
        animationsReducer,
        testimonialsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            illustrationAPI.middleware,
            animationAPI.middleware,
            testimonialAPI.middleware,
        ]),
});
