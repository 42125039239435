import { createSlice } from '@reduxjs/toolkit';

// -> initialstate
const initialState = {
    animations: [],
};

// !############### SLICE ###############
const animationsSlice = createSlice({
    name: 'animationsSlice',
    initialState,
    reducers: {
        setAnimations: (state, action) => {
            state.animations = action.payload;
        },
    },
});

export const { setAnimations } = animationsSlice.actions;
export default animationsSlice.reducer;
