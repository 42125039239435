import { createSlice } from '@reduxjs/toolkit';

// -> initialstate
const initialState = {
    testimonials: [],
};

// !############### SLICE ###############
const testimonialsSlice = createSlice({
    name: 'testimonialsSlice',
    initialState,
    reducers: {
        setTestimonials: (state, action) => {
            state.testimonials = action.payload;
        },
    },
});

export const { setTestimonials } = testimonialsSlice.actions;
export default testimonialsSlice.reducer;
