import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Box, Button, Stack, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../assets/media/images/logo.png';
import { colors } from '../assets/utils/colors';

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
    boxShadow: 'none',
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const TopBar = ({ mobileOpen, handleDrawerToggle }) => {
    return (
        <>
            <AppBar position="fixed">
                <Toolbar
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    {/* ################### LEFT ################# */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {mobileOpen ? (
                            <IconButton
                                onClick={handleDrawerToggle}
                                color="inherit"
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    marginTop: 1.3,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={handleDrawerToggle}
                                color="inherit"
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    marginTop: 1.3,
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: {sm: 400, xs: '100%'},
                        }}
                    >
                        <Box
                            sx={{
                                width: 120,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            }}
                        >
                            <Button>Illustration</Button>
                        </Box>
                        <Box>
                            <Typography
                                noWrap
                                component="div"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Avatar
                                    src={logo}
                                    variant="square"
                                    sx={{ width: 80 }}
                                />
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: 120,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            }}
                        >
                            <Button>Animation</Button>
                        </Box>
                    </Box>

                    {/* ################### RIGHT ################# */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    ></TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
