import * as React from 'react';
import Box from '@mui/material/Box';
import AboutUs from '../components/AboutUs';

const About = () => {
    const ref = React.useRef(null);

    return (
        <Box sx={{ pb: 7 }} ref={ref}>
            {/* ############### Content ################### */}
            <Box>
                <AboutUs />
            </Box>
        </Box>
    );
};

export default About;
