import * as React from 'react';
import Box from '@mui/material/Box';
import Illustrations from '../components/Illustrations';

export default function FixedBottomNavigation() {
    const ref = React.useRef(null);
    return (
        <Box sx={{ pb: 7 }} ref={ref}>
            {/* ############### Content ################### */}
            <Box>
                <Illustrations />
            </Box>
        </Box>
    );
}
