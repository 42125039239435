// -> Illustration endpoinst
const GET_CHILD_ILLUSTRATIONS = '/illustrations/child';
const GET_MISCELLANEOUS_ILLUSTRATIONS = '/illustrations/miscellaneous';
export const illustrationEndpoints = {
    GET_CHILD_ILLUSTRATIONS,
    GET_MISCELLANEOUS_ILLUSTRATIONS,
};


// -> Animation endpoints
const GET_ALL_ANIMATIONS = '/animations';
export const animationEndpoints = {
    GET_ALL_ANIMATIONS,
};


// -> Testimonials endpoints
const GET_ALL_TESTIMONIALS = '/testimonials';
export const testimonialEndpoints = {
    GET_ALL_TESTIMONIALS,
};

