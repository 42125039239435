import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import { colors } from '../assets/utils/colors';
import { Box } from '@mui/material';

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// ############### STYLED DRAWER HEADER #################
const DrawerToggleBtn = styled(Box)(({ theme }) => ({
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'padding': theme.spacing(1, 1),
    '& .icon': {
        'padding': 1,
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'fontWeight': 'bold',
        '&:hover': {
            backgroundColor: colors.secondary,
            color: colors.primary,
            fontWeight: 'bold',
        },
    },
}));

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    'width': drawerWidth,
    'flexShrink': 0,
    'whiteSpace': 'nowrap',
    'boxSizing': 'border-box',
    '& .MuiPaper-root': {
        backgroundColor: colors.primary,
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

//! #################### MAIN FUNC ######################
const SideBar = ({ handleDrawerToggle, mobileOpen }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const AppBarHeight = styled('div')(({ theme }) => ({
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const { window } = 'props';
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ################# DRAWER CONTENT ################
    const drawer = <div></div>;
    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    'display': { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: '70%', // Change the width of drawer in mobile
                        backgroundColor: colors.primary,
                    },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ##################### desktop ################ */}
            <DrawerDesktop
                // variant="permanent"
                open={open}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
            >
                <AppBarHeight />
                {/* ========== Drawer desk toggle button ======== */}
                <DrawerToggleBtn
                    sx={{
                        position: 'absolute',
                        top: 60,
                        zIndex: 1098,
                        right: -5,
                    }}
                >
                    {open ? (
                        <IconButton
                            className="icon"
                            onClick={handleDrawerClose}
                            sx={{
                                '&.icon': {
                                    border: `2.5px solid ${colors.primary}`,
                                },
                            }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    ) : (
                        <IconButton
                            className="icon"
                            onClick={handleDrawerOpen}
                            sx={{
                                '&.icon': {
                                    border: `2.5px solid ${colors.primary}`,
                                },
                            }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    )}
                </DrawerToggleBtn>
                <Divider />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default SideBar;
