import * as React from 'react';
import Box from '@mui/material/Box';
import Animations from '../components/Animations';

const Animation = () => {
    const ref = React.useRef(null);
    return (
        <Box sx={{ pb: 7 }} ref={ref}>
            {/* ############### Content ################### */}
            <Box>
                <Animations />
            </Box>
        </Box>
    );
};

export default Animation;
