import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { animationEndpoints } from './endpoints';
import { baseURL } from './baseURL';

export const animationAPI = createApi({
    reducerPath: 'animationAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        getAllAnimations: builder.query({
            query: () => `${animationEndpoints.GET_ALL_ANIMATIONS}`,
        }),
    }),
});

export const { useGetAllAnimationsQuery } = animationAPI;
