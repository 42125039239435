import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Box, Grid, Typography, styled, useMediaQuery } from '@mui/material';
import CustomDialog from './dialogs/CustomDialog';
import {
    useGetChildIllustrationsQuery,
    useGetMiscellaneousIllustrationsQuery,
} from '../api/illustrationAPI';
import { useDispatch, useSelector } from 'react-redux';
import {
    setChildIllustrations,
    setMiscellaneousIllustrations,
} from '../state/features/illustrationsSlice';
import { filter } from 'lodash';
import { colors } from '../assets/utils/colors';
import FullAppLoader from './FullAppLoader';

const SectionHead = styled(Box)({
    'marginTop': 50,
    'marginBottom': 20,
    'padding': 10,
    'position': 'relative',
    '& .head': {
        'fontSize': 18,
        'fontWeight': 'bold',
        'opacity': 0.7,
        // 'textAlign': 'center',
        // 'display': 'flex',
        'justifyContent': 'center',
        ':before': {
            content: "''",
            height: 8,
            width: 100,
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
            position: 'absolute',
            bottom: 0,
            borderRadius: 50,
        },
    },
});

const Illustrations = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const [openDialog, setOpenDialogy] = React.useState(false);
    const [illustratorPreview, setIllustratorPreview] = React.useState({});

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // -> Handle Illustrator preview
    const handleIllustratorPreview = (illustrator_id) => {
        let illustrator = filter(childIllustrations, { id: illustrator_id });
        setIllustratorPreview(illustrator[0]);
        setOpenDialogy(true);
    };
    const handleMiscellaneousPreview = (illustrator_id) => {
        let illustrator = filter(miscellaneousIllustrations, {
            id: illustrator_id,
        });
        setIllustratorPreview(illustrator[0]);
        setOpenDialogy(true);
    };

    // -> API
    const {
        isSuccess: childSuccess,
        isLoading: childLoading,
        data: childData,
    } = useGetChildIllustrationsQuery();
    const {
        isSuccess: miscellaneousSuccess,
        isLoading: miscLoading,
        data: miscellaneousData,
    } = useGetMiscellaneousIllustrationsQuery();

    // -> Redux state
    const dispatch = useDispatch();
    const { childIllustrations, miscellaneousIllustrations } = useSelector(
        (state) => state.illustrationsReducer,
    );

    // -> UseEffect
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (childSuccess) {
                dispatch(setChildIllustrations(childData.data));
            }
            if (miscellaneousSuccess) {
                dispatch(setMiscellaneousIllustrations(miscellaneousData.data));
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [
        dispatch,
        childSuccess,
        childData,
        miscellaneousSuccess,
        miscellaneousData,
    ]);

    return (
        <>
            {childLoading || miscLoading ? (
                <><FullAppLoader /></>
            ) : (
                <>
                    {/* ##################### DIALOG ################# */}
                    <CustomDialog
                        head={illustratorPreview.title}
                        customWidth={'md'}
                        openDialog={openDialog}
                        handleDialogClose={handleDialogClose}
                    >
                        <Grid container>
                            <Grid item sm={12}>
                                <ImageListItem>
                                    <img
                                        src={`${illustratorPreview.file}?w=248&fit=crop&auto=format`}
                                        srcSet={`${illustratorPreview.file}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        alt={'godwill'}
                                        loading="lazy"
                                        style={{
                                            height: matches ? `80vh` : '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </ImageListItem>
                            </Grid>
                        </Grid>
                    </CustomDialog>

                    {/* ##################### CONTENT ################# */}
                    <Grid container justifyContent={'center'} padding={3}>
                        <Grid item sm={9} xs={12}>
                            <SectionHead>
                                <Typography className="head">
                                    Children books illustrations
                                </Typography>
                            </SectionHead>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <ImageList
                                cols={matches ? 4 : 2}
                                gap={25}
                                variant="standard"
                            >
                                {childIllustrations?.map(
                                    (illustration, index) => {
                                        return (
                                            <ImageListItem
                                                key={index}
                                                sx={{
                                                    'cursor': 'pointer',
                                                    ':hover': {
                                                        opacity: 0.5,
                                                        transition: `.2s all ease-in-out`,
                                                    },
                                                    'borderRadius': 2,
                                                    'overflow': 'hidden',
                                                }}
                                                onClick={() =>
                                                    handleIllustratorPreview(
                                                        illustration.id,
                                                    )
                                                }
                                            >
                                                <img
                                                    src={`${illustration.file}?w=248&fit=crop&auto=format`}
                                                    srcSet={`${illustration.file}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={illustration.title}
                                                    loading="lazy"
                                                    style={{
                                                        borderRadius: 10,
                                                    }}
                                                />
                                                <ImageListItemBar
                                                    title={illustration.title}
                                                    // subtitle={<span>by: {item.author}</span>}
                                                    position="below"
                                                    sx={{ opacity: 0.8 }}
                                                />
                                            </ImageListItem>
                                        );
                                    },
                                )}
                            </ImageList>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <SectionHead>
                                <Typography className="head">
                                    Miscellaneous
                                </Typography>
                            </SectionHead>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <ImageList
                                cols={matches ? 4 : 2}
                                gap={25}
                                variant="standard"
                            >
                                {miscellaneousIllustrations?.map(
                                    (illustration, index) => {
                                        return (
                                            <ImageListItem
                                                key={index}
                                                sx={{
                                                    'cursor': 'pointer',
                                                    ':hover': {
                                                        opacity: 0.5,
                                                        transition: `.2s all ease-in-out`,
                                                    },
                                                }}
                                                onClick={() =>
                                                    handleMiscellaneousPreview(
                                                        illustration.id,
                                                    )
                                                }
                                            >
                                                <img
                                                    src={`${illustration.file}?w=248&fit=crop&auto=format`}
                                                    srcSet={`${illustration.file}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={illustration.title}
                                                    loading="lazy"
                                                    style={{
                                                        borderRadius: 10,
                                                    }}
                                                />
                                                <ImageListItemBar
                                                    title={illustration.title}
                                                    // subtitle={<span>by: {item.author}</span>}
                                                    position="below"
                                                    sx={{ opacity: 0.8 }}
                                                />
                                            </ImageListItem>
                                        );
                                    },
                                )}
                            </ImageList>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default Illustrations;
