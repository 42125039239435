import React from 'react';
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/colors';
import moment from 'moment/moment';
import { Launch } from '@mui/icons-material';

const TestmonialWidget = ({ testimonial }) => {
    return (
        <>
            <Box
                sx={{
                    'p': 4,
                    'opacity': 0.9,
                    'borderRadius': 10,
                    'borderTopLeftRadius': 15,
                    'borderBottomRightRadius': 15,
                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    'position': 'relative',
                    ':before': {
                        content: "''",
                        height: 100,
                        width: 100,
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        borderTop: `4px solid ${colors.secondary}`,
                        borderLeft: `4px solid ${colors.secondary}`,
                        borderTopLeftRadius: 15,
                    },
                    ':after': {
                        content: "''",
                        height: 100,
                        width: 100,
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        borderRight: `4px solid ${colors.secondary}`,
                        borderBottom: `4px solid ${colors.secondary}`,
                        borderBottomRightRadius: 15,
                    },
                }}
            >
                <Typography sx={{ fontSize: 14, opacity: 0.9 }}>
                    {testimonial.testimonial}
                </Typography>
                <Box>
                    <List
                        sx={{
                            width: '100%',
                            pb: 0,
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    src={testimonial.file}
                                    sx={{
                                        p: 1,
                                        mr: 2,
                                        width: 60,
                                        height: 60,
                                        border: `1px solid ${colors.secondary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={testimonial.name}
                                secondary={moment(testimonial.posted).format(
                                    'll',
                                )}
                            />
                        </ListItem>
                    </List>
                </Box>
                <a href={testimonial.url} target="_blank" rel="noreferrer">
                    <IconButton
                        color="info"
                        sx={{
                            position: 'absolute',
                            right: 15,
                            bottom: 10,
                            zIndex: 999,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                        }}
                    >
                        <Launch />
                    </IconButton>
                </a>
            </Box>
        </>
    );
};

export default TestmonialWidget;
