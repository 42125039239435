import { createSlice } from '@reduxjs/toolkit';

// -> initialstate
const initialState = {
    childIllustrations: [],
    miscellaneousIllustrations: [],
};

// !############### SLICE ###############
const illustrationsSlice = createSlice({
    name: 'illustrationsSlice',
    initialState,
    reducers: {
        setChildIllustrations: (state, action) => {
            state.childIllustrations = action.payload;
        },
        setMiscellaneousIllustrations: (state, action) => {
            state.miscellaneousIllustrations = action.payload;
        },
    },
});

export const {
    setChildIllustrations,
    setMiscellaneousIllustrations,
} = illustrationsSlice.actions;
export default illustrationsSlice.reducer;
