import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from '../pages/About';
import Animation from '../pages/Animation';
import Login from '../pages/auth/Login';
import Dashboard from '../pages/Dashboard';
import Homepage from '../pages/Homepage';
import AppLayout from '../pages/layouts/AppLayout';
import HomeLayout from '../pages/layouts/HomeLayout';
import GuestRoutes from './GuestRoutes';
import PrivateRoutes from './PrivateRoutes';
import Books from '../pages/Books';

// ?############## GUEST ELEMENTS ###########
const LoginElement = () => <Login />;
const HomepageElement = () => (
    <HomeLayout>
        <Homepage />
    </HomeLayout>
);

const AnimationsElement = () => (
    <HomeLayout>
        <Animation />
    </HomeLayout>
);

const BooksElement = () => (
    <HomeLayout>
        <Books />
    </HomeLayout>
);

const AboutElement = () => (
    <HomeLayout>
        <About />
    </HomeLayout>
);

// ?############## PROTECTED ELEMENTS ###########
const DashboardElement = () => (
    <AppLayout>
        <Dashboard />
    </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* ################## GUEST ROUTES ################## */}
                <Route element={<GuestRoutes />}>
                    <Route path="/" element={<HomepageElement />} />
                    <Route path="/about" element={<AboutElement />} />
                    <Route path="/animations" element={<AnimationsElement />} />
                    <Route path="/books" element={<BooksElement />} />
                    <Route path="/login" element={<LoginElement />} />
                </Route>

                {/* ################## PROTECTED ROUTES ################## */}
                <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<DashboardElement />} />
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default App;
