import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Box, Grid, Typography, styled, useMediaQuery } from '@mui/material';
import { useGetAllAnimationsQuery } from '../api/animationAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setAnimations } from '../state/features/animationsSlice';
import CustomDialog from './dialogs/CustomDialog';
import { filter } from 'lodash';
import { colors } from '../assets/utils/colors';
import FullAppLoader from './FullAppLoader';

const SectionHead = styled(Box)({
    'marginTop': 50,
    'marginBottom': 20,
    'padding': 10,
    'position': 'relative',
    '& .head': {
        'fontSize': 18,
        'fontWeight': 'bold',
        'opacity': 0.7,
        // 'textAlign': 'center',
        // 'display': 'flex',
        'justifyContent': 'center',
        ':before': {
            content: "''",
            height: 8,
            width: 100,
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
            position: 'absolute',
            bottom: 0,
            borderRadius: 50,
        },
    },
});

export default function Animations() {
    const matches = useMediaQuery('(min-width:600px)');
    const [openDialog, setOpenDialogy] = React.useState(false);
    const [animationPreview, setAnimationPreview] = React.useState({});

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // -> Handle Illustrator preview
    const handleAnimationPreview = (animation_id) => {
        let animation = filter(animations, { id: animation_id });
        setAnimationPreview(animation[0]);
        setOpenDialogy(true);
    };

    // -> API
    const { data, isSuccess, isLoading } = useGetAllAnimationsQuery();

    // -> Redux state
    const dispatch = useDispatch();
    const { animations } = useSelector((state) => state.animationsReducer);

    // -> UseEffect
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setAnimations(data.data));
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [dispatch, isSuccess]);
    return (
        <>
            {isLoading ? (
                <>
                    <FullAppLoader />
                </>
            ) : (
                <>
                    {/* ##################### DIALOG ################# */}
                    <CustomDialog
                        head={animationPreview.title}
                        customWidth={'md'}
                        openDialog={openDialog}
                        handleDialogClose={handleDialogClose}
                    >
                        <Grid container>
                            <Grid item sm={12}>
                                <ImageListItem>
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        style={{
                                            width: '100%',
                                            height: matches ? `75vh` : '100%',
                                        }}
                                    >
                                        <source
                                            src={`${animationPreview.file}?w=248&fit=crop&auto=format`}
                                            srcSet={`${animationPreview.file}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            alt={animationPreview.title}
                                            loading="lazy"
                                            type="video/mp4"
                                        />
                                    </video>
                                </ImageListItem>
                            </Grid>
                        </Grid>
                    </CustomDialog>

                    {/* ##################### CONTENT ################# */}
                    <Grid container justifyContent={'center'} padding={3}>
                        <Grid item sm={9} xs={12}>
                            <SectionHead>
                                <Typography className="head">
                                    Animations
                                </Typography>
                            </SectionHead>
                        </Grid>
                        <Grid item sm={9}>
                            <ImageList
                                cols={matches ? 4 : 2}
                                gap={25}
                                variant="standard"
                            >
                                {animations?.map((animation, index) => {
                                    return (
                                        <ImageListItem
                                            key={index}
                                            sx={{
                                                'cursor': 'pointer',
                                                ':hover': {
                                                    opacity: 0.5,
                                                    transition: `.2s all ease-in-out`,
                                                },
                                            }}
                                            onClick={() =>
                                                handleAnimationPreview(
                                                    animation.id,
                                                )
                                            }
                                        >
                                            <video
                                                autoPlay
                                                loop
                                                muted
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <source
                                                    src={`${animation.file}?w=248&fit=crop&auto=format`}
                                                    srcSet={`${animation.file}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={animation.title}
                                                    loading="lazy"
                                                    type="video/mp4"
                                                />
                                            </video>
                                            <ImageListItemBar
                                                title={animation.title}
                                                // subtitle={
                                                //     <span>by: {animation.author}</span>
                                                // }
                                                position="below"
                                            />
                                        </ImageListItem>
                                    );
                                })}
                            </ImageList>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
