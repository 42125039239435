// !####### MAIN FUNC ##########
const useAuth = () => {
    // -> CHECK AUTHENTICATION
    let authenticated = false;

    // -> CHECK AUTHORIZATION
    let authorization = '';

    // -> USER TOKEN
    let token = '';

    // -> AUTH RETURN
    const auth = {
        token,
        authenticated,
        authorization,
    };

    return auth;
};

export default useAuth;
