import * as React from 'react';
import Box from '@mui/material/Box';
import { colors } from '../../assets/utils/colors';
import BottomBar from '../../components/BottomBar';
import TopNavbar from '../../components/TopNavbar';

const HomeLayout = ({ children }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    bottom: '100%',
                    right: 0,
                    left: 0,
                }}
            >
                {/* ############### Top Navbar ################ */}
                <TopNavbar />

                {/* ############### BottomBar ###############*/}
                <BottomBar />

                {/* ############### content ###############*/}
                <Box
                    component="main"
                    sx={{
                        bgcolor: colors.bgColor1,
                        flexGrow: 1,
                        marginLeft: 0,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </>
    );
};

export default HomeLayout;
