import React from 'react';
import { Avatar, Box, Grid, Typography, styled } from '@mui/material';
import mort from '../assets/media/images/mort.jpg';
import banner from '../assets/media/images/banner1.png';
import { colors } from '../assets/utils/colors';
import Contacts from './Contacts';
import TestmonialWidget from './widgets/TestmonialWidget';
import { useGetAllTestimonialsQuery } from '../api/testimonialAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setTestimonials } from '../state/features/testimonialsSlice';
import FullAppLoader from './FullAppLoader';

const SectionHead = styled(Box)({
    'marginBottom': 20,
    'padding': 10,
    'position': 'relative',
    '& .head': {
        'fontSize': 18,
        'fontWeight': 'bold',
        'opacity': 0.7,
        // 'textAlign': 'center',
        // 'display': 'flex',
        'justifyContent': 'center',
        ':before': {
            content: "''",
            height: 8,
            width: 100,
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
            position: 'absolute',
            bottom: 0,
            borderRadius: 50,
        },
    },
});

const About = () => {
    // -> API
    const { data, isSuccess, isLoading } = useGetAllTestimonialsQuery();

    // -> Redux state
    const dispatch = useDispatch();
    const { testimonials } = useSelector((state) => state.testimonialsReducer);

    // -> UseEffect
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setTestimonials(data.data));
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [dispatch, isSuccess, data]);

    return (
        <>
            {isLoading ? (
                <>
                    <FullAppLoader />
                </>
            ) : (
                <>
                    <Grid container justifyContent={'center'}>
                        <Grid item sm={9} xs={12}>
                            <Box
                                sx={{
                                    height: 230,
                                    backgroundImage: `url(${banner})`,
                                    position: 'relative',
                                    backgroundPosition: 'center center',
                                    backgroundSize: `100%, ${{
                                        sm: 250,
                                        xs: 300,
                                    }}`,
                                    opacity: 0.4,
                                    borderBottomLeftRadius: 25,
                                    backgroundRepeat: 'no-repeat',
                                }}
                            >
                                <Grid container>
                                    <Grid item sm={4}></Grid>
                                </Grid>
                            </Box>
                            <Grid container>
                                <Grid
                                    item
                                    sm={4}
                                    xs={12}
                                    sx={{
                                        position: 'relative',
                                        bottom: 100,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: 200,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                height: 180,
                                                width: 180,
                                                borderBottom: `5px solid ${colors.secondary}`,
                                            }}
                                            src={mort}
                                        />
                                    </Box>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                opacity: 0.8,
                                                color: colors.primary,
                                            }}
                                        >
                                            Godwill Belium
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 15,
                                                opacity: 0.8,
                                                color: colors.primary,
                                                fontStyle: 'italic',
                                            }}
                                        >
                                            Illustrator & 2D Animator
                                        </Typography>
                                    </Box>
                                    <Contacts />
                                    <Box sx={{ p: 4 }}>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                opacity: 0.8,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Digital artist experienced in
                                            children book illustrations,
                                            stylized portraits, 2d animation as
                                            well as graphics design including
                                            motion graphics... can do pretty
                                            much anything related to 2d arts and
                                            designs
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={8}>
                                    <Box
                                        sx={{
                                            position: { xs: 'relative' },
                                            bottom: { md: 0, xs: 100 },
                                        }}
                                    >
                                        <SectionHead>
                                            <Typography className="head">
                                                Testimonials
                                            </Typography>
                                        </SectionHead>
                                        <Box
                                            sx={{
                                                height: { md: 435, xs: '100%' },
                                                overflow: 'auto',
                                            }}
                                        >
                                            <Grid
                                                container
                                                padding={2}
                                                spacing={2}
                                            >
                                                {testimonials?.map(
                                                    (testimonial, index) => (
                                                        <Grid
                                                            item
                                                            sm={6}
                                                            xs={12}
                                                            key={index}
                                                        >
                                                            <TestmonialWidget
                                                                testimonial={
                                                                    testimonial
                                                                }
                                                            />
                                                        </Grid>
                                                    ),
                                                )}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default About;
