import {
    Animation,
    MenuBookOutlined,
    Person2Outlined,
    SportsKabaddi,
} from '@mui/icons-material';
import { Box, List, ListItem, ListItemButton, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../assets/utils/colors';
import { NavLink } from 'react-router-dom';

const BottomBar = () => {
    const links = [
        {
            id: 1,
            title: 'Illustrations',
            icon: <SportsKabaddi className="icon" />,
            url: '/',
        },
        {
            id: 2,
            title: 'Animations',
            icon: <Animation className="icon" />,
            url: '/animations',
        },
        {
            id: 3,
            title: 'Books',
            icon: <MenuBookOutlined className="icon" />,
            url: '/books',
        },
        {
            id: 4,
            title: 'About',
            icon: <Person2Outlined className="icon" />,
            url: '/about',
        },
    ];
    return (
        <>
            <Box
                sx={{
                    height: 55,
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                }}
            >
                <List
                    sx={{
                        'position': { md: 'relative', xs: 'fixed' },
                        'height': 55,
                        'bottom': 0,
                        'right': 0,
                        'left': 0,
                        'p': 0,
                        'display': 'flex',
                        'justifyContent': 'space-between',
                        'alignItems': 'center',
                        '& .item': {
                            'display': 'flex',
                            'flexDirection': 'column',
                            // 'width': '25%',
                            '& .btn': {
                                display: 'flex',
                                flexDirection: 'column',
                                px: { md: 4, xs: '100%' },
                                textAlign: 'center',
                            },
                            '& .icon': {
                                opacity: 0.8,
                                fontSize: 20,
                            },
                            '& .title': {
                                fontSize: 13,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            },
                        },
                    }}
                >
                    {links?.map((link) => (
                        <NavLink
                            to={link.url}
                            style={{ width: '100%' }}
                            key={link.id}
                        >
                            {({ isActive }) => (
                                <ListItem className="item">
                                    <ListItemButton
                                        className="btn"
                                        sx={{
                                            color: isActive
                                                ? colors.secondary
                                                : 'auto',
                                        }}
                                    >
                                        {link.icon}
                                        <Typography
                                            className="title"
                                            sx={{
                                                color: isActive
                                                    ? colors.secondary
                                                    : 'auto',
                                            }}
                                        >
                                            {link.title}
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </NavLink>
                    ))}
                </List>
            </Box>
        </>
    );
};

export default BottomBar;
