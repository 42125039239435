import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { illustrationEndpoints } from './endpoints';
import { baseURL } from './baseURL';

export const illustrationAPI = createApi({
    reducerPath: 'illustrationAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        getChildIllustrations: builder.query({
            query: () => `${illustrationEndpoints.GET_CHILD_ILLUSTRATIONS}`,
        }),
        getMiscellaneousIllustrations: builder.query({
            query: () =>
                `${illustrationEndpoints.GET_MISCELLANEOUS_ILLUSTRATIONS}`,
        }),
    }),
});

export const {
    useGetChildIllustrationsQuery,
    useGetMiscellaneousIllustrationsQuery,
} = illustrationAPI;
