import getEnv from '../helpers/getEnv';

// => Dev base url
const devBaseUrl = 'http://127.0.0.1:1010/api';

// => Pro base url
const proBaseUrl = 'https://secure.mortbelium.com/api';

const getBaseURL = () => {
    let env = getEnv('NODE_ENV');
    if (env === 'dev') {
        return devBaseUrl;
    }
    return proBaseUrl;
};

export const baseURL = getBaseURL();
