import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { testimonialEndpoints } from './endpoints';
import { baseURL } from './baseURL';

export const testimonialAPI = createApi({
    reducerPath: 'testimonialAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        getAllTestimonials: builder.query({
            query: () => `${testimonialEndpoints.GET_ALL_TESTIMONIALS}`,
        }),
    }),
});

export const { useGetAllTestimonialsQuery } = testimonialAPI;
