import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { primaryTheme } from './assets/utils/themes';
import AppRoutes from './routes/App.routes';

// !#################### MAIN FUNC ##################
const App = () => {
    return (
        <React.Fragment>
            <ThemeProvider theme={primaryTheme}>
                <CssBaseline />
                <AppRoutes />
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
